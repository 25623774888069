import Header from '../Sections/Header';
import Footer from '../Sections/Footer';
import Sidebar from '../Sections/Sidebar';
import tcsTSX from '../Cache/tcsTSX.json';
import tcsTSXV from '../Cache/tcsTSXV.json';
import tcsCSE from '../Cache/tcsCSE.json';
import { useEffect, useRef } from "react";


function Home() {

    const pU = process.env.PUBLIC_URL;

    const subscribeBoxRef = useRef(null);

    useEffect(() => {
        
        document.title = 'Home - Shortdata.ca';
        document.querySelector('meta[property="og:title"]').setAttribute("content", 'Home - Shortdata.ca');
        document.querySelector('link[rel="canonical"]').href = 'https://shortdata.ca';
        document.querySelector('meta[property="og:url"]').setAttribute("content", 'https://shortdata.ca');
        document.querySelector('meta[property="og:type"]').setAttribute("content", 'website');

        document.getElementsByTagName('body')[0].className = 'home page-template page-template-template-home page-template-template-home-php page page-id-65 with-sidebar';

        const script2 = document.createElement('script');
        script2.src = pU + "/wp-content/themes/shortdata/library/js/main.js";
        script2.async = false;
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script2);
        }

    }, []);

    let cnwslbusy = 0;
    let cnwslfail = 0;
    let cemailnwslpreg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    function handleSubsciption(e) {
        e.preventDefault();
        if(cnwslbusy == 0) {
            cnwslbusy = 1;

            let icemailnewsletter = subscribeBoxRef.current.value.replace(/\s/g, "");
            if(icemailnewsletter == '') cnwslfail = 1;
	        if(cemailnwslpreg.test(icemailnewsletter) == false) cnwslfail = 1;

            if(cnwslfail) {
                cnwslbusy = 0;
                cnwslfail = 0;
                subscribeBoxRef.current.focus();
                alert('Please Enter Your Email Address Correctly!');
            }
            else {
                fetch('/newssnl.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: "semail="+icemailnewsletter                    
                })
                .then(res => res.text())
                .then(data => {
                    cnwslbusy = 0;
                    cnwslfail = 0;
                    subscribeBoxRef.current.value = '';
                    alert('Thank you for subscribing!');
                });
            }
        }        
    }

    return (
        <>
            <div id="homeextra">
                <div id="container">
                    <div id="page-wrap">
                        <noscript>
                            <div id="js-disabled">
                                Javascript is required for our website's full capabilities!				
                            </div>
                        </noscript>
                        <Header menuitem='home'/>                        
                        <div id="content">
                            <div id="inner-content" className="wrap floatingdiamondmargin">
                                <main id="main" role="main" itemScope itemProp="mainContentOfPage" itemType="http://schema.org/Blog">
                                    <article id="post-65" style={{"marginTop":"18px"}} className="cf post-65 page type-page status-publish hentry" role="article" itemScope itemType="http://schema.org/BlogPosting">
                                        <section className="entry-content" itemProp="articleBody">
                                            <div className="content-wrap">
                                                <div style={{"paddingBottom":"0","paddingTop":"0","marginTop":"0", cursor:"pointer"}} onClick={() => {
                            window.open("https://value-trades.blogspot.com/2024/12/blockmate-unambiguously-undervalued-crypto-stock.html?m=1", '_blank').focus();
                        }}>
                                                    <div className="mushroombigc">
                                                        <div className="mushroombigic" style={{"textAlign":"center", background: "white", border: "10px solid #850f2b"}}>
                                                            <div className="mushroomtextsmallc" style={{"textAlign":"center","width":"100%", color: "green"}}>
                                                                <div className="mushroomtextsmallbc blink_me">SHORT SQUEEZE ALERT!!!</div><br />
                                                                <div className="mushroomtextsmallbc">TSX.V: MATE</div><br />
                                                                <div className="mushroomtextsmallbc">(Read The Report)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="home-hero-article" style={{"paddingBottom":"0","paddingTop":"0","marginTop":"0"}}>
                                                    <div className="mushroombigc">
                                                        <div className="mushroombigic" style={{"textAlign":"center"}}>
                                                            <div className="mushroomtextsmallc" style={{"textAlign":"center","width":"100%"}}>
                                                                <div className="mushroomtextsmallbc">Watch how we crush the market every time!</div>
                                                                <div className="mushroomtextsmallnc">Our next one drops soon. Be the first to know.</div>
                                                                <div className="mushroomtextsmallnc">Sign up Free Today!</div>
                                                            </div>
                                                            <div className="nwslmaincoptemailc">
                                                                <div className="nwslmaincoptemail"><input type="text" id="icemailnewsletter" autoComplete="off" placeholder="your email address" maxLength="75" ref={subscribeBoxRef}/></div>
                                                                <div className="nwslmaincoptjoin" id="nwslmaincoptjoinnwsl" onClick={handleSubsciption}>JOIN NOW!</div>
                                                            </div>
                                                            <div className="mushroomicsmalld">All good no spamming here just pure data and news</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="home-table" className="data-table">
                                                    <div className="inner-data-table">
                                                        <div className="data-controls">
                                                            <div className="left-data-controls">
                                                                <button className="option exchange-tsx active" data-owns="tsx" data-hovb="TSX">TSX</button>
                                                                <button className="option exchange-tsxv" data-owns="tsxv" data-hovb="TSX VENTURE">TSX VENTURE</button>
                                                                <button className="option exchange-cse" data-owns="cse" data-hovb="CSE">CSE</button>	
                                                            </div>
                                                            <div className="right-data-controls pseudo-select">
                                                                <button className="option option1 option-lnd current" data-owns="lnd">
                                                                    <div className="description">Largest Net Decreases</div>
                                                                    <div className="sub-description">{tcsTSX['dataHeadingTo']}</div>
                                                                </button>
                                                                <button className="option option1 option-lnd active" data-owns="lnd">
                                                                    <div className="description">Largest Net Decreases</div>
                                                                    <div className="sub-description">{tcsTSX['dataHeadingTo']}</div>
                                                                </button>
                                                                <button className="option option2 option-lpd" data-owns="lpd">
                                                                    <div className="description">Largest Percent Decreases</div>
                                                                    <div className="sub-description">{tcsTSX['dataHeadingTo']}</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div id="tcs-tsx-tab" className="tcs-tab active">
                                                            <div id="tcs-tsx-lnd-table" className="data-table-wrapper data-table active">
                                                                <div className="responsive-table tcs-table">
                                                                    <div className="table-head">
                                                                        <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                        <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsTSX['tableHeadingTo']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsTSX['tableHeadingFrom']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">NET CHANGE</span></div>
                                                                        <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                    </div>
                                                                    <div className="table-body">
                                                                    {
                                                                        Object.keys(tcsTSX['lnd']).map((stemp, index) => 
                                                                            <a key={index} className="row" href={pU+"/stock/"+tcsTSX['lnd'][stemp].security}>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lnd'][stemp].symbol}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lnd'][stemp].company_name}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lnd'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lnd'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lnd'][stemp].net_change.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className={tcsTSX['lnd'][stemp].per_change >= 0 ? "col-content no-arrow-desktop no-color up" : "col-content no-arrow-desktop no-color down"}>{tcsTSX['lnd'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                            </a>
                                                                        )
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="tcs-tsx-lpd-table" className="data-table-wrapper data-table">
                                                                <div className="responsive-table tcs-table">
                                                                    <div className="table-head">
                                                                        <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                        <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsTSX['tableHeadingTo']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsTSX['tableHeadingFrom']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                    </div>
                                                                    <div className="table-body">
                                                                    {
                                                                        Object.keys(tcsTSX['lpd']).map((stemp, index) => 
                                                                            <a key={index} className="row" href={pU+"/stock/"+tcsTSX['lpd'][stemp].security}>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lpd'][stemp].symbol}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lpd'][stemp].company_name}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lpd'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lpd'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className={tcsTSX['lpd'][stemp].per_change >= 0 ? "col-content no-color up" : "col-content no-color down"}>{tcsTSX['lpd'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                            </a>
                                                                        )
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="tcs-tsxv-tab" className="tcs-tab ">
                                                            <div id="tcs-tsxv-lnd-table" className="data-table-wrapper data-table ">
                                                                <div className="responsive-table tcs-table">
                                                                    <div className="table-head">
                                                                        <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                        <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsTSXV['tableHeadingTo']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsTSXV['tableHeadingFrom']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">NET CHANGE</span></div>
                                                                        <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                    </div>
                                                                    <div className="table-body">
                                                                    {
                                                                        Object.keys(tcsTSXV['lnd']).map((stemp, index) => 
                                                                            <a key={index} className="row" href={pU+"/stock/"+tcsTSXV['lnd'][stemp].security}>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lnd'][stemp].symbol}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lnd'][stemp].company_name}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lnd'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lnd'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lnd'][stemp].net_change.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className={tcsTSXV['lnd'][stemp].per_change >= 0 ? "col-content no-arrow-desktop no-color up" : "col-content no-arrow-desktop no-color down"}>{tcsTSXV['lnd'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                            </a>
                                                                        )
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="tcs-tsxv-lpd-table" className="data-table-wrapper data-table">
                                                                <div className="responsive-table tcs-table">
                                                                    <div className="table-head">
                                                                        <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                        <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsTSXV['tableHeadingTo']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsTSXV['tableHeadingFrom']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                    </div>
                                                                    <div className="table-body">
                                                                    {
                                                                        Object.keys(tcsTSXV['lpd']).map((stemp, index) => 
                                                                            <a key={index} className="row" href={pU+"/stock/"+tcsTSXV['lpd'][stemp].security}>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lpd'][stemp].symbol}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lpd'][stemp].company_name}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lpd'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lpd'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className={tcsTSXV['lpd'][stemp].per_change >= 0 ? "col-content no-color up" : "col-content no-color down"}>{tcsTSXV['lpd'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                            </a>
                                                                        )
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="tcs-cse-tab" className="tcs-tab ">
                                                            <div id="tcs-cse-lnd-table" className="data-table-wrapper data-table ">
                                                                <div className="responsive-table tcs-table">
                                                                    <div className="table-head">
                                                                        <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                        <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsCSE['tableHeadingTo']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsCSE['tableHeadingFrom']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">NET CHANGE</span></div>
                                                                        <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                    </div>
                                                                    <div className="table-body">
                                                                    {
                                                                        Object.keys(tcsCSE['lnd']).map((stemp, index) => 
                                                                            <a key={index} className="row" href={pU+"/stock/"+tcsCSE['lnd'][stemp].security}>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lnd'][stemp].symbol}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lnd'][stemp].company_name}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lnd'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lnd'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lnd'][stemp].net_change.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className={tcsCSE['lnd'][stemp].per_change >= 0 ? "col-content no-arrow-desktop no-color up" : "col-content no-arrow-desktop no-color down"}>{tcsCSE['lnd'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                            </a>
                                                                        )
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="tcs-cse-lpd-table" className="data-table-wrapper data-table">
                                                                <div className="responsive-table tcs-table">
                                                                    <div className="table-head">
                                                                        <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                        <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsCSE['tableHeadingTo']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsCSE['tableHeadingFrom']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                    </div>
                                                                    <div className="table-body">
                                                                    {
                                                                        Object.keys(tcsCSE['lpd']).map((stemp, index) => 
                                                                            <a key={index} className="row" href={pU+"/stock/"+tcsCSE['lpd'][stemp].security}>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lpd'][stemp].symbol}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lpd'][stemp].company_name}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lpd'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lpd'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className={tcsCSE['lpd'][stemp].per_change >= 0 ? "col-content no-color up" : "col-content no-color down"}>{tcsCSE['lpd'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                            </a>
                                                                        )
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tables-legend">SHORT POSITION DATA ANALYSIS PROVIDED BY HARRIS MARKET CONSULTING BASED ON REPORTED DATA TO IRROC BY EACH INDIVIDUAL MARKETPLACE WHERE TRADES WERE MARKED AS SHORT SALES. SHORT POSITIONS AS OF {tcsTSX['tableHeadingTo']} BASED ON AGGREGATE CHANGES FROM {tcsTSX['tableHeadingFrom']} REPORTING.</div>
                                            </div>
                                        </section>
                                    </article>
                                </main>
                                
                                <Sidebar />
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <div id="overlay-for-mobile-menu"></div>
                </div>
            </div>
        </>
    );
}

export default Home;